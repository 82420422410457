import React from "react"
import { graphql, Link } from "gatsby"
import Seo from "../components/seo"
import "../styles/theme.scss"
import Carousel from "../components/carousel";
import Scotiabank from "../images/logo.scotiabank.svg"
import Layout from "../layouts/layout";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const Home = ({ data, location }) => {
    let global = data.contentfulWebsite;
    
    return (
        <Layout pathname={location.pathname}>
            <Seo metaTitle={global.title} metaDescription={global.description} author={global.author} shareImage={global.shareImage.file.url} />
            <div className="o-wrapper o-container o-content">
                <Carousel slides={data.contentfulHomepage.cards}></Carousel>
                <div className="o-wrapper o-wrapper--small o-content-spacer o-content-container u-text-center">
                    <div className="h2">{data.contentfulHomepage.aboutFilmTitle}</div>
                    <hr />
                    <div className="o-flex o-flex--third">
                        <img className="u-hide u-hide--mobile" src={data.contentfulHomepage.firstImage.resize.src} alt={global.title} />
                        <div className="o-flex__col-2 u-text-left o-content-container">
                            <div className="h1">{global.title}</div>
                            {documentToReactComponents(JSON.parse(data.contentfulHomepage.aboutFilmDescription.raw))}
                        </div>
                    </div>
                    <Link className="u-block" to="https://www.scotiabank.com/ca/en/about/sponsorships/hockey-for-all.html" target="_blank" rel="noreferrer">
                        <div className="c-logo c-logo--xlarge u-center"><img src={Scotiabank} alt="Scotiabank" /></div>
                        <img className="u-border" src={data.contentfulHomepage.secondImage.resize.src} alt={global.title} />
                    </Link>
                </div>
            </div>
        </Layout>
    );
};

export default Home

export const pageQuery = graphql`
{
    contentfulWebsite(title: {eq: "Black Ice"}) {
        author
        description
        title
        videoCtaText
        podcastCtaText
        tagline
        partnerTitle
        shareImage {
            file {
                url
            }
        }
    }
    contentfulHomepage(website: {title: {eq: "Black Ice"}}) {
        aboutFilmTitle
        aboutFilmDescription {
            raw
        }
        firstImage {
            resize(width: 300) {
                src
            }
        }
        secondImage {
            resize(width: 1900) {
                src
            }
        }
        cards {
            title
            subtitle
            buttonText
            image {
                resize(width: 1900) {
                    src
                }
            }
            url
        }
    }
}
`;
